<template>
  <el-card shadow="never">
    <el-form
        :model="ruleForm"
        :rules="rules"
        status-icon
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
    >
      <el-form-item label="客户名称" prop="name">
        <el-input v-model="ruleForm.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="大类别" prop="levelOneTypeId">
        <el-select v-model="value" placeholder="请选择" clearable>
          <el-option
              v-for="item in bigOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="中类别" prop="levelSecondTypeId">
        <el-select v-model="value3" placeholder="请选择" clearable>
          <el-option
              v-for="item in midOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开始服务时间" prop="serverStartTime">
        <el-date-picker
            v-model="ruleForm.serverStartTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束服务时间" prop="serverEndTime">
        <el-date-picker
            v-model="ruleForm.serverEndTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态" prop="status" v-if="false">
        <el-input
            v-model="ruleForm.status"
            autocomplete="off"
            placeholder="点击保存 状态默认为进行中"
            :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="ak" prop="ak">
        <el-input v-model="ruleForm.ak" autocomplete="off" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="sk" prop="sk">
        <el-input v-model="ruleForm.sk" autocomplete="off" :disabled="true"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="saveCustomer(ruleForm)" style="background: #1d90ff; border:none" :disabled="this.show">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import $ from "jquery";
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
       rules: {
        name: [
          {required: true, message: "请输入客户名称", trigger: "blur"},
        ],
        levelOneTypeId: [
          {required: true, message: "请选择大类别", trigger: "blur"},
        ],
        levelSecondTypeId: [
          {required: true, message: "请选择中类别", trigger: "blur"},
        ],
        serverStartTime: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        serverEndTime: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "change",
          },
        ],
      },
      ruleForm: {
        name: "",
        serverStartTime: "",
        serverEndTime: "",
        ak: "",
        sk: "",
      },
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      value2: "",
      bigOptions: [],
      midOptions: [],
      value: "",
      value3: "",
       show:false,
       newVal:0,
    };
  },
  watch: {
    value: {
      handler(newVal,oldVal) {
        this.newVal = newVal; 
        console.log(this.newVal);
        this.$api.get(
          "/type/getMidTypeByBigTypeId",
          null,
          {
            list: false,
            bigTypeId: this.newVal,

          },
          (successRes) => {
            console.log(successRes);
            this.midOptions = successRes.data;
            // console.log(" type="+this.midOptions)
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
      
      },
    }
  },
  methods: {
    rTime(date) {
      var json_date = new Date(date).toJSON();
      return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    saveCustomer() {
       this.show = true;
      this.$api.post(
          "/customer/save",
          null,
          {
            name: this.ruleForm.name,
            serverStartTime: this.rTime(this.ruleForm.serverStartTime),
            serverEndTime: this.rTime(this.ruleForm.serverEndTime),
            levelOneTypeId: this.value,
            levelSecondTypeId: this.value3,
            ak: this.ruleForm.ak,
            sk: this.ruleForm.sk,
            status: "1",
          },
          (successRes) => {
            if (successRes.status == 200) {
              this.$message('操作成功');
             
              this.$router.push({name: "customerList"});
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
    },

    getBigTypeList() {
      this.$api.get(
          "/type/big/list",
          null,
          {
            list: false
          },
          (successRes) => {
            console.log(successRes);
            this.bigOptions = successRes.data.rows;
            this.ruleForm.ak = uuidv4().replace("-","").replace("-","").replace("-","").replace("-","");
            this.ruleForm.sk = uuidv4().replace("-","").replace("-","").replace("-","").replace("-","");
          },
          (failureRes) => {
            console.log(failureRes);
          }
      );
    },
  },
  mounted() {
    this.getBigTypeList();
  },
};
</script>